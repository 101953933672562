import React from "react";
// import Button from "../../elements/Button/Button";
 

const RecommendedCourseCard = (props) => {
  const { image, description, orientation_image } = props.course;
  
  let courseCardClasses = "recommended-courses__course";
  
  return (
    <div className={courseCardClasses} id="courses">
       
      <div className="recommended-courses__course-image">
        <img src={image} alt="" style={{height:"360px", width:"360px"}}/>         
      </div>      
      <div className="recommended__card-description">
        <p>{description}</p>
      </div>
       
    </div>
  );
};

export default RecommendedCourseCard;
