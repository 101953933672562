import React from "react";

const ObjetivoCard = (props) => {
  const { image, title, description, orientation_image } = props.objetivo;
  
  let courseCardClasses = "services-all__objetive";
  
  return (
    <div className={courseCardClasses} id="courses">
      <div className="services-all__objetive-image">
        <img src={image} alt="" style={{height:"360px", width:"360px"}}/>         
      </div>
      <div>
        <div className="services-all__objetive-title">
            <h3>{title}</h3>
        </div>
        <div className="services-all__objetive-description">
            <p>{description}</p>
        </div>
      </div>
       
    </div>
  );
};

export default ObjetivoCard;
