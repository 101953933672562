import React, { useState } from "react";
import Hero from "../../components/commons/Hero/Hero";
import calendarImage from "./calendar-img.svg";
import "./style.css";

import moment from "moment";
import "moment/locale/es";
moment.locale("es");


const getDaysInMonth = (year, month) => {
  return new Date(year, month + 1, 0).getDate();
};

const getMonths = (startYear, endYear) => {
  const months = [];
  for (let year = startYear; year <= endYear; year++) {
    for (let month = 0; month < 12; month++) {
      const daysInMonth = getDaysInMonth(year, month);
      const monthName = new Date(year, month).toLocaleString("es-ES", { month: "long" });
      const days = [];
      for (let day = 1; day <= daysInMonth; day++) {
        const date = new Date(year, month, day);
        const dayName = date.toLocaleString("es-ES", { weekday: "short" });
        days.push({ day, name: dayName });
      }
      months.push({ name: monthName, days });
    }
  }
  return months;
};


const Calendar = () => {
  const [year, setYear] = useState(new Date().getFullYear());
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  // const months = getMonths(new Date().getFullYear(), new Date().getFullYear() + 1);
  const [months, setMonths] = useState(getMonths(new Date().getFullYear(), new Date().getFullYear() + 1));

  const handlePreviousMonth = () => {
    setCurrentMonth((currentMonth - 1 + months.length) % months.length);
    let newMonth = (currentMonth - 1 + months.length) % months.length;
    let newYear = new Date().getFullYear();
    if (newMonth === 11) {
      newYear--;
    }
    setYear(newYear);
    setMonths(getMonths(newYear, newYear + 1));
  };

  const handleNextMonth = () => {
    setCurrentMonth((currentMonth + 1) % months.length);
    let newMonth = (currentMonth + 1) % months.length;
    let newYear = new Date().getFullYear();
    if (newMonth === 0) {
      newYear++;
      
    }
    setYear(newYear);
    setMonths(getMonths(newYear, newYear + 1));
  };

  const currentMes = months[currentMonth];


  function getTime(day) {
    const date = new Date(`April ${day}, 2021 20:00:00 GMT-05:00`);
    // const date = new Date(2020, 8, day, 20, 0, 0);
    return " " + moment(date).fromNow();
  }

  const marzo = [
    {
      day: 1,
      name: "LUN",
    },
    {
      day: 2,
      name: "MAR",
    },
    {
      day: 3,
      name: "MIE",
    },
    {
      day: 4,
      name: "JUE",
    },
    {
      day: 5,
      name: "VIE",
      category: "live",
      description: "Creando una web desde cero con HTML y CSS",
      time: "9:00pm GMT-5 ",
    },
    {
      day: 8,
      name: "LUN",
    },
    {
      day: 9,
      name: "MAR",
    },
    {
      day: 10,
      name: "MIE",
    },
    {
      day: 11,
      name: "JUE",
      category: "read",
      description: "Club de Lectura - Eloquent Javascript",
      time: "10:00pm GMT-5 ",
    },
    {
      day: 12,
      name: "VIE",
      category: "live",
      description: "Creando una web desde cero con HTML y CSS - Parte 2",
      time: "9:15pm GMT-5 ",
    },
    {
      day: 15,
      name: "LUN",
    },
    {
      day: 16,
      name: "MAR",
    },
    {
      day: 17,
      name: "MIE",
    },
    {
      day: 18,
      name: "JUE",
    },
    {
      day: 19,
      name: "VIE",
      category: "live",
      description: "TBD",
      time: "8:00pm GMT-5 ",
    },
    {
      day: 22,
      name: "LUN",
    },
    {
      day: 23,
      name: "MAR",
    },
    {
      day: 24,
      name: "MIE",
    },
    {
      day: 25,
      name: "JUE",
    },
    {
      day: 26,
      name: "VIE",
      category: "live",
      description: "TBD",
      time: "8:00pm GMT-5 ",
    },
    {
      day: 29,
      name: "LUN",
    },
    {
      day: 30,
      name: "MAR",
    },
    {
      day: 31,
      name: "MIE",
    },
  ];

  const events = [
    { date: "2024-10-30", 
      name: "VIE", 
      category: "live", 
      description: "Creando una web desde cero con HTML y CSS", 
      time: "9:00pm GMT-5" 
    },
    { date: "2024-10-11",
      name: "JUE",
      category: "read",
      description: "Club de Lectura - Eloquent Javascript",
      time: "10:00pm GMT-5 ",
    }
  ];
  
  // const currentMonth = marzo.map((item) => {
  //   return {
  //     ...item,
  //     timeAgo: getTime(item.day),
  //   };
  // });

  // const [calendar, setCalendar] = useState(currentMonth);

  return (
    <div>
      <Hero title="Calendario de actividades del mes" image={calendarImage} />
      <h1 className="calendar__title">{currentMes.name} {year}</h1>
      <button onClick={handlePreviousMonth} className="calendar__button">Mes anterior</button>
      <button onClick={handleNextMonth} className="calendar__button">Mes siguiente</button>
      <section className="groups-description">
        <div className="calendar">
          
          <div className="calendar__header">LUN</div>
          <div className="calendar__header">MAR</div>
          <div className="calendar__header">MIÉ</div>
          <div className="calendar__header">JUE</div>
          <div className="calendar__header">VIE</div>
          <div className="calendar__header">SAB</div>
          <div className="calendar__header">DOM</div>
          {/* <div className="calendar__item vacio"></div> */}
          {/* <div className="calendar__item vacio"></div>
          <div className="calendar__item vacio"></div>
          <div className="calendar__item vacio"></div> */}
          {currentMes.days.map((day) => {
            // const eventFind = events.find((event) => new Date(event.date).getDate() === day.day);
            const eventFind = events.find((event) => {
              const eventDate = new Date(event.date);
              return eventDate.getFullYear() === new Date().getFullYear() &&
                     eventDate.getMonth() === currentMonth &&
                     eventDate.getDate() === day.day;
            });
            // console.log("🚀 ~ {currentMes.days.map ~ day.day:", day.day)
            // console.log("🚀 ~ {currentMes.days.map ~ eventFind:", eventFind)
            return (
              <div className="calendar__item" key={day.day} >
                <div className="item__dayGroup">
                  <p className="item__dayName">{eventFind ? eventFind.name : ""}</p>
                  <p className="item__day">{day.day}</p>
                </div>
                {eventFind && eventFind.category ? (
                  <div className="item__card">
                    <p className={`card__category ${eventFind.category}`}>
                      {eventFind.category}
                    </p>
                    <p className="card__description">{eventFind.description}</p>
                    <p className="card__time">{eventFind.time}</p>
                    <p>
                      <i className="far fa-clock"> </i>
                      {eventFind.timeAgo}
                    </p>
                  </div>
                ) : null}
              </div>
            );
          })}
        </div>
      </section>
    </div>
  );
};

export default Calendar;
