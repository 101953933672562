import React from "react";
// import Header from "../../components/commons/Header/Header";
import RecommendedServices from "../../components/commons/RecomendationServices/RecommendedServices";
import ServicesAll from "../../components/commons/Services/ServicesAll";
import {Header2} from "../../components/commons/Header/Header2";

const Home = () => {
    return (
      <div>
        <Header2 />
        <RecommendedServices />
        <ServicesAll />
      </div>
    );
  };
  
  export default Home;
  