import React, { useState, useEffect } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Layout from "../components/commons/Layout/Layout";
import Home from "../containers/Home/Hone";
import AboutUs from "../containers/AboutUs/AboutUs";
import Calendar from "../containers/Calendar/calendar";

// import "../styles.css";

const App = () => {
    return (
        <BrowserRouter>
        <Layout>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/nosotros" component={AboutUs} />
            <Route exact path="/calendario" component={Calendar} />
          </Switch>
        </Layout> 
        </BrowserRouter>
    )
}

export default App;
