import React from "react";
import headerImage from "./images/aboutUs.svg";
import "./style.css";

const Header_About = () => (
  <header className="header_about">
    <div className="header_about__content">
    <div className="header_about__logos">
        <img src={headerImage} alt="" />
      </div>
      <div className="header_about__text">
        <h1>
          <span>Nosotros</span>
        </h1>
        <p>Somos un equipo de educadores comprometidos,  con  calidad de servicio; orientada a   la formación continua de los integrantes de la comunidad educativa y  la mejora del  desempeño  en sus competencias profesionales</p>
      </div>
      
    </div>
  </header>
);

export default Header_About;
