import React from "react";
// import Button from "../../elements/Button/Button";
// import imagen1 from "./images/opcion1.png"

const ServicesCard = (props) => {
  const { image, title, description, orientation_image } = props.service;
  
  let courseCardClasses = "services-all__service";
  
  return (
    <div className={courseCardClasses} id="courses">
      <div className="services-all__service-image">
        <img src={image} alt="" style={{height:"360px", width:"360px"}}/>         
      </div>
      <div>
        <div className="services-all__service-title">
            <h3>{title}</h3>
        </div>
        <div className="services-all__service-description">
            <p>{description}</p>
        </div>
      </div>
       
    </div>
  );
};

export default ServicesCard;
