import React from "react";
import Header_About from "../../components/commons/AboutUs/Header_About";
import Objetivo from "../../components/commons/Objetivos/Objetivo";

// import RecommendedServices from "../../components/commons/RecomendationServices/RecommendedCourses";
// import ServicesAll from "../../components/commons/Services/ServicesAll";

const AboutUs = () => {
    return (
      <div>
        <Header_About />
        {/* <RecommendedServices /> */}
        <Objetivo />
      </div>
    );
  };
  
  export default AboutUs;
  