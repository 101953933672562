export const types = {
  login: "LOGIN",
  logout: "LOGOUT",
  setRecommendedCourses: "SET_RECOMMENDED_COURSES",
  setGroups: "SET_GROUPS",
  setCategories: "SET_CATEGORIES",
  setLessons: "SET_LESSONS",
  getCurrentLesson: "GET_LESSON",
  setLessonsCategories: "SET_LESSONS_CATEGORIES",
  addQuestion: "ADD_QUESTION",
  addQuestionLike: "ADD_LIKE_QUESTION",
  getCurrentQuestion: "GET_QUESTION",
  getLessonQuestions: "GET_LESSON_QUESTIONS",
  addComment: "ADD_COMMENT",
  getQuestionComments: "GET_QUESTION_COMMENTS",
  getLessonQuestionsComments: "GET_LESSON_QUESTIONS_COMMENTS",
  addLike: "ADD_LIKE",
  getLessonQuestionsLikes: "GET_LESSON_QUESTIONS_LIKES",
  getLessonResources: "GET_LESSON_RESOURCES",
};
