import React from "react";
// import headerImage from "./home.svg";
import Carousel, { autoplayPlugin,Dots } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
// import Slide1 from "./images/slogan_FYE.webp"
import Slide1 from "./images/1.png"
import Slide2 from "./images/2.png"
import Slide3 from "./images/3.png"
// import "./style.css";

const slides_info = [
  {
    title: 'Slide1',
    url: Slide1,
  },
  {
    title: 'Slide2',
    url: Slide2,
  },
  {
    title: 'Slide3',
    url: Slide3,
  },
]

function imageFucion() {
  return slides_info.map((image, index) => (
    <img
      src={image.url}
      key={`title_${index}_${Math.random()}`}
      style={{ height: 'calc(100vh - 100px)', width: 'inherit' }}
    />
  ))
}

class Header2 extends React.Component {
  constructor(props) {
    super(props)

    const slides = imageFucion()
    console.log('🚀 ~ Header2 ~ constructor ~ slides:', slides)

    this.state = {
      value: 0,
      slides: slides,
    }

    this.onChange = this.onChange.bind(this)
  }

  onChange(value) {
    this.setState({ value })
  }

  render() {
    return (
      <div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexFlow: 'row',
            alignContent: 'center',
            textAlign: 'center',
          }}
        >
          {/* <input
          type="number"
          value={this.state.value}
          onChange={e => this.onChange(parseInt(e.target.value || 0))}
        /> */}
          <Carousel
            value={this.state.value}
            slides={this.state.slides}
            onChange={this.onChange}
            plugins={[
              'infinite',
              'centered',
              'clickToChange',
              {
                resolve: autoplayPlugin,
                options: {
                  interval: 4000,
                  stopOnInteraction: false,
                },
              },
            ]}
            // draggable={false}
            animationSpeed={1500}
          />
        </div>
        <Dots
          value={this.state.value}
          onChange={this.onChange}
          number={this.state.slides.length}
        />
      </div>
    )
  }
};

export {Header2};