import React from "react";

const SocialIcon = ({ link, icon }) => {
  return (
    <>
      <a target="_blank" href={link} rel="noopener noreferrer">
        {icon}
      </a>
    </>
  );
};

export default SocialIcon;
