import React from "react";
import SocialIcon from "../../elements/SocialIcon/SocialIcon";
import "./style.css";
import { AiFillFacebook,AiOutlineInstagram,AiOutlineTwitter,AiOutlineDiscord,AiOutlineYoutube } from "react-icons/ai";

const d = new Date();
const year = d.getFullYear();

const Footer = () => (
  <footer className="footer">
    <div className="container">
      <div className="footer__social">
        <SocialIcon
          link="https://www.facebook.com/codinglatam/"
          icon={<AiFillFacebook className="icon"/>}
        />
        <SocialIcon
          link="https://www.instagram.com/codinglatam/"
          icon={<AiOutlineInstagram className="icon"/>}
        />
        <SocialIcon link="https://discord.gg/7bpGcDT" icon={<AiOutlineDiscord className="icon"/>} />
        <SocialIcon link="https://twitter.com/codinglatam" icon={<AiOutlineTwitter className="icon"/>} />
        <SocialIcon
          link="https://www.youtube.com/channel/UCS0emIw9cgv9RIMowHlq3ng"
          icon={<AiOutlineYoutube className="icon"/>}
        />
      </div>
      <div className="footer__content">
        <p>© FYE {year} - Todos los derechos reservados</p>
      </div>
    </div>
  </footer>
);

export default Footer;
