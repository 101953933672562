import React from "react";
import { useSelector } from "react-redux";
import RecommendedCard from "./RecommendedCard";
import CardSkeleton from "../Skeletons/CardSkeleton";
import "./style.css";

// import imagen1 from "./images/opcion_1.webp"
import imagen1 from "./images/Imagen1.png"
import imagen2 from "./images/Imagen2.png"
import imagen3 from "./images/Imagen3.png"

const RecommendedServices = () => {
    
    const cursosDisponibles = {
      Services: [
        //status, image, title, description, link, releaseDate
        {
          image: imagen1,
          description:
            'Somos un equipo de educadores comprometidos con una educación orientada hacia la excelencia educativa, que promueven la práctica transformacional en los actores de la educación alineado al enfoque crítico reflexivo',
        },
        {
          image: imagen2,
          description:
            'Brindamos formación continua con servicio de calidad acorde al contexto e identidad de la Institución Educativa.',
        },
        {
          image: imagen3,
          description:
            'Ofrecemos asesorías y acompañamiento personalizado acorde a las características y necesidades de cada Institución educativa',
        },
      ],
    }

    const recommendedServices = useSelector((state) => cursosDisponibles);
  
    // useEffect(() => {
    //   dispatch(getRecommendedServicesFirebase());
    // }, []);

    return (
        <div className="recommended-services">
        <div className="recommended-services__content">
          <div className="recommended-services__intro">
            <h2 id="description">¿Por que elegirnos?</h2>
          </div>
          {recommendedServices?.Services !== null ? (
            recommendedServices?.Services?.map((course) => (
              <RecommendedCard course={course} key={Math.random()} />
            ))
          ) : (
            <CardSkeleton />
          )}
        </div>
      </div>
    )

}

export default RecommendedServices;
